#nav-main {
    z-index: 1;

    .nav-main-home {
        display: flex;

        &--responsive {
            display: none;
        }

        svg {
            fill: $color-text;
            margin-bottom: 4px;
            transition: $duration;
        }

        &:hover,
        &:focus {
            svg {
                fill: $color-main;
            }
        }
    }
    
    .menu__nav__list {
        display: flex;
        align-items: center;
        transition: $duration ease-out;
    } 

    .nav-main-item {
        height: 100px;
        display: flex;
        align-items: center;

        &:nth-child(2) {
            margin-left: 39px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            margin-left: 54px;
        }

        &:last-child {     
            margin-left: 42px;

            .nav-main-link {
                width: 233px;
                height: 48px;
                color: $color-white;
                background-color: $color-main;

                &::after {
                    width: 0;
                    height: 0;
                }

                &:hover,
                &:focus {
                    color: $color-text;
                    background-color: $color-second--dark;
                }
            }
        }

        &.menu-item-has-children:hover, 
        &.menu-item-has-children:focus, 
        &.menu-item-has-children:focus-within {
            &:last-child {
                .nav-main-link {
                    color: $color-text;
                    background-color: $color-second--dark;
                }
            }

            .nav-main-link {
                color: $color-main;

                &::after {
                    opacity: 1;
                }
            }

            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        .nav-main-link {
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: uppercase;
            padding: 8px;
            transition: $duration;

            &::after {
                content: '';
                width: 50px;
                height: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $color-second;
                border-radius: $border-radius--round;
                opacity: 0;
                transition: $duration;
                z-index: -1;
            }
        }

        .sub-menu {
            display: none;
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background: $color-main;
            padding: 30px 15px;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, 
            .nav-grandchild-item {
                width: 100%;
                display: inline-flex;
                line-height: initial;
                padding: 0;
                margin: -1px; // Fix space beetween inline-flex

                a {
                    width: 100%;
                    height: 50px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-weight: $font-weight-medium;
                    color: $color-white;
                    padding: 5px 10px 5px 30px;
                    margin-bottom: 1px; // Fix space beetween inline-flex
                    transition: $duration;

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        left: 0;
                        color: $color-second--dark;
                        transition: $duration;
                    }

                    &:hover {
                        padding-left: 45px;

                        &::after {
                            left: 15px;
                            color: $color-white;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    font-size: $font-size--text-small;
                    line-height: initial;
                    font-weight: $font-weight;
                    text-transform: initial;
                    display: flex;
                    padding: 5px 10px 5px 45px;

                    &::after {
                        left: 20px;
                    }

                    &:hover {
                        padding-left: 60px;

                        &::after {
                            left: 35px;
                        }
                    }
                } 
            }
        }
    }
}


// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    font-size: $font-size--text-large;
    font-weight: $font-weight-bold; 
    color: $color-text;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;

    .navicon {
        width: 20px;
        height: 2px;
        position: relative;
        display: block;
        background-color: $color-text;
        margin-right: 8px;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            background: $color-text;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        #nav-main {
            .nav-main-item {    
                &:nth-child(2) {
                    margin-left: 22px;
                }
        
                &:nth-child(3),
                &:nth-child(4) {
                    margin-left: 25px;
                }    
                    
                &:last-child {    
                    margin-left: 23px; 
                    
                    .nav-main-link {
                        width: 180px;
                    }
                }

                .nav-main-link {
                    font-size: $font-size--text-large;
                }
            }
        }

    }


    // 960
    @media screen and (max-width: $medium) {
        #nav-main {
            display: flex;
            align-items: center;
            column-gap: 50px;

            .nav-main-home {
                display: none;

                &--responsive {
                    display: flex;
                }
            }

            .menu__nav__list {
                width: 100%;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 100px;
                left: 0;
                background: $color-main;
            }

            .nav-main-item {
                width: 100%;
                height: auto;
                flex-direction: column;

                &:first-child {
                    display: none;
                }

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:last-child {
                    margin: 0;
                }

                &:last-child,
                &:last-child:hover,
                &:last-child:focus {
                    .nav-main-link {
                        width: 100%;
                        height: 60px;
                        color: $color-white;
                        background-color: transparent;
                        border-bottom: 0;
                    }
                }
                
                .nav-main-link {
                    width: 100%;
                    max-width: 100%;
                    height: 60px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    color: $color-white;
                    text-transform: uppercase;
                    padding: 0 20px;
                    border-bottom: 1px solid $color-white;
                }

                &.menu-item-has-children:hover,
                &.menu-item-has-children:focus,
                &.menu-item-has-children:focus-within {
                    .nav-main-link {
                        color: $color-white;

                        &::after {
                            opacity: 0;
                        }
                    }

                    .sub-menu {
                        display: none;
                    }
                }

                &.active,
                &.menu-item-has-children.active {
                    background: $color-second;

                    .nav-main-link {
                        border-bottom: 1px solid $color-second;
                        color: $color-text;
                    }

                    .sub-menu {
                        display: flex;
                        position: relative;
                        padding: 20px;
                        border-bottom: 1px solid $color-white;

                        .nav-drop {
                            column-count: 1;
                        }
                    }
                }

            }

            // Responsive button
            .nav-main_button {
                ~ .menu {
                    clear: both;
                    transform: scaleY(0);
                    transform-origin: 50% 0;
                    transition:transform .3s ease;
                }

                &:checked {
                    ~ .menu {
                        transform: scaleY(1);
                    }
                    ~ .nav-main_icon {
                        .navicon {
                            background: $color-bg--transparent;

                            &:before {
                                transform: rotate(-45deg);
                            }

                            &:after {
                                transform: rotate(45deg);
                            }
                        }

                        &:not(.steps) {
                            .navicon {
                                &:before,
                                &:after {
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }

            // Responsive button label
            .nav-main_icon {
                display: flex;                
            }
        }
        
    }


    // 640
    @media screen and (max-width: $small) {
        #nav-main {
            column-gap: 32px;

            .menu__nav__list {
                top: 80px;
            }
        }
    }
