//======================================================================================================
// Général
//======================================================================================================
.front-page {
    * {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4 {
        padding: 0;
        margin: 0;
        border: 0;
    }



    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;

        .identity {
            position: absolute;
            top: 100px;
            z-index: 2;

            &__title {
                font-family: $font-family;
                font-size: 80px;
                line-height: 70px;
                font-weight: $font-weight-black;
                color: $color-white;
                text-transform: uppercase;
                text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
            }
        }

        &__effect {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 3;

            svg {
                width: 2840px;
                height: 100px;
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                fill: $color-white;
            }
        }

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
            }

            &--navigation, 
            &--pagination {
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .slideshow {
            width: 100%;
            height: 580px;

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
            }

            &__container {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;

                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 2.66%, rgba(0, 0, 0, 0) 56.73%);
                    z-index: 1;
                }
            }

            &__content {
                position: absolute;
                left: 15px;
                bottom: 205px;
                z-index: 2;
            }

            &__title {
                font-size: 25px;
                line-height: 24px;
                font-weight: $font-weight-bold;
                color: $color-white;
                text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
            }

            &--video {
                img {
                    display: none;
                }
            }

            &__pagination {
                position: absolute;
                left: 15px;
                bottom: 160px;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 15px;
                z-index: 2;
        
                .swiper-pagination-bullet {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border: 3px solid $color-white;
                    border-radius: $border-radius--round;
                    box-shadow: 0px 0px 15px rgba(0, 0, 0, .6);
                    opacity: 1;
                    transition: $duration;
        
                    &-active,
                    &:hover {
                        background-color: $color-second--dark;
                    }
                }
            }
        
        }
    }



    .ac-qa {
        position: relative;
        margin-top: -20px;
        z-index: 3;

        &__container {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &::before {
                content: '';
                width: 810px;
                height: 810px;
                position: absolute;
                top: -56px;
                right: 90.8%;
                background-color: $color-second;
                border-radius: $border-radius--round;
                z-index: -1;
            }

            &::after {
                content: '';
                width: 273px;
                height: 273px;
                position: absolute;
                top: 210px;
                left: 86.4%;
                background-color: $color-second;
                border-radius: $border-radius--round;
                z-index: -1;
            }
        }
    }



    //======================================================================================================
    // Actualités
    //======================================================================================================
    .ac {
        width: 770px;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &__title {
            font-size: 44px;
            line-height: 1.1em;
            color: $color-text;
            text-transform: uppercase;

            span {
                display: block;
                font-size: 24px;
                color: $color-fourth;
                margin-top: -5px;
            }
        }

        &__pagination {
            display: flex;
            column-gap: 15px;
            margin-bottom: 17px;

            &--responsive {
                display: none;
            }

            .swiper-pagination-bullet {
                width: 20px;
                height: 20px;
                background-color: $color-white;
                border: 3px solid $color-fourth;
                border-radius: $border-radius--round;
                opacity: 1;
                transition: $duration;

                &-active,
                &:hover {
                    background-color: $color-fourth--rgba;
                }
            }
        }

        &__items {
            padding: 0 15px 25px;
            margin: 20px -15px 0;

        }

        &-item {
            width: 370px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__img {
                width: 100%;
                height: 345px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: $duration;
                }

                &--no-image {
                    background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
                }
            }

            &__content {
                width: 330px;
                height: 185px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 10px;
                background-color: $color-white;
                padding: 10px 25px;
                margin-top: -41px;
                box-shadow: $shadow;
                z-index: 2;
            }

            &__title {
                font-family: $font-family;
                font-size: $font-size--4;
                color: $color-fourth;
                text-align: center;
                transition: $duration;
            }

            &__text {
                line-height: 1.2em;
                color: $color-dark;
                text-align: center;
            }

            &:hover,
            &:focus {
                .ac-item {
                    &__img {
                        img {
                            transform: scale(1.2);
                        }
                    }

                    &__title {
                        color: $color-main;
                    }
                }
            }
        }

        &__button {
            width: 270px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: uppercase;
            text-align: center;
            margin: 0 auto;
            border: 1px solid $color-main;
            transition: $duration;

            &:hover,
            &:focus {
                color: $color-white;
                background-color: $color-main;
            }
        }
    }



    //======================================================================================================
    // Quick Access
    //======================================================================================================
    .qa {
        position: relative;
        margin-top: 111px;

        &__items {
            position: relative;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            z-index: 1;
        }

        &-item {
            width: 330px;
            height: 100px;
            display: flex;
            align-items: center;
            column-gap: 30px;
            background-color: $color-white;
            padding: 5px 15px;
            box-shadow: $shadow;
            transition: $duration;

            &__title {
                font-family: $font-family--heading;
                font-size: 24px;
                line-height: 1.2em;
                font-weight: $font-weight-bold;
                color: $color-text;
                text-transform: uppercase;
                transition: $duration;

                span {
                    font-size: $font-size--text-medium;
                }
            }

            svg {
                fill: $color-main;
                transition: $duration;
                path {
                    fill: $color-main;
                    transition: $duration;
                }
            }

            &:hover,
            &:focus {
                background-color: $color-main;

                .qa-item__title {
                    color: $color-white;
                }

                svg {
                    fill: $color-white;
                    path {
                        fill: $color-white;
                    }
                }
            }
        }
    }



    //======================================================================================================
    // Agenda
    //======================================================================================================
    .ag {
        position: relative;
        margin-top: 101px;

        .container {
            position: relative;

            &::after {
                content: '';
                width: 810px;
                height: 810px;
                position: absolute;
                top: -82px;
                left: 92.3%;
                background-color: $color-third;
                border-radius: $border-radius--round;
                z-index: -1;
            }
        }

        &__title {
            font-size: 44px;
            line-height: 1.1em;
            color: $color-text;
            text-transform: uppercase;

            span {
                display: block;
                font-size: 24px;
                color: $color-main;
                margin-top: -5px;
            }
        }

        &__items {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }

        &__smallitems {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
        }

        &-bigitem {
            width: 560px;
            position: relative;
            display: flex;
            align-items: flex-start;

            &__img {
                width: 359px;
                height: 502px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: $duration;
                }

                &--no-image {
                    background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
                }            
            }

            &__infos {
                position: absolute;
                top: -27px;
                right: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            &__dates {
                width: 290px;
                height: 290px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 10px;
                padding: 0 0 15px 15px;
                z-index: -1;

                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    background-color: $color-third;
                    border-radius: $border-radius--round;
                    z-index: -1;
                }

                svg {
                    width: 24px;
                    height: 14px;
                    fill: $color-main;
                }
            }

            &-date {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: $font-family--heading;
                font-weight: $font-weight-bold;
                color: $color-text;
                text-transform: uppercase;
        
                &__day {
                    font-size: 50px;
                    line-height: 1.1em;
                }
        
                &__month {
                    font-size: 34px;
                    margin-top: -4px;
                }
            }

            &__content {
                width: 260px;
                max-height: 250px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 10px;
                background-color: $color-white;
                padding: 30px 25px;
                margin-top: -34px;
                box-shadow: $shadow;
            }

            &__title {
                font-family: $font-family;
                font-size: $font-size--4;
                line-height: 1.1em;
                color: $color-main;
                transition: $duration;
            }

            &__text {
                line-height: 1.25em;
                color: $color-text;
            }

        
            &:hover,
            &:focus {
                .ag-bigitem {
                    &__img img {
                        transform: scale(1.2);
                    }

                    &__title {
                        color: $color-fourth;
                    }
                }
            }
        }

        &-smallitem {
            width: 540px;
            position: relative;
            display: flex;
            align-items: center;

            &__img {
                width: 170px;
                height: 236px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: $duration;
                }

                &--no-image {
                    background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
                }            
            }

            &__dates {
                width: 100px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 8px;
                z-index: -1;

                &::after {
                    content: '';
                    width: 212px;
                    height: 212px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: $color-third;
                    border-radius: $border-radius--round;
                    z-index: -1;
                }

                svg {
                    width: 14px;
                    height: 8px;
                    fill: $color-main;
                }
            }

            &-date {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: $font-family--heading;
                font-weight: $font-weight-bold;
                color: $color-text;
                text-transform: uppercase;
        
                &__day {
                    font-size: $font-size--2;
                    line-height: 1.1em;
                }
        
                &__month {
                    font-size: 20px;
                    margin-top: -2px;
                }
            }

            &__content {
                width: 270px;
                height: 156px;
                display: flex;
                align-items: center;
                background-color: $color-white;
                padding: 30px 25px;
                box-shadow: $shadow;
            }

            &__title {
                font-family: $font-family;
                font-size: $font-size--4;
                line-height: 1.1em;
                color: $color-main;
                transition: $duration;
            }

        
            &:hover,
            &:focus {
                .ag-smallitem {
                    &__img img {
                        transform: scale(1.2);
                    }

                    &__title {
                        color: $color-fourth;
                    }
                }
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
        }

        &__button {
            width: 270px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: uppercase;
            text-align: center;
            border: 1px solid $color-main;
            transition: $duration;

            &:hover,
            &:focus {
                color: $color-white;
                background-color: $color-main;
            }
        }
    }



    .kio-news {
        position: relative;
        margin-top: 107px;

        &__container {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__effect {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 122px;
            z-index: -1;

            svg {
                width: 2840px;
                height: 542px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);            
            }
        }
    }



    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque {
        width: 600px;

        &__title {
            font-size: 44px;
            line-height: 1.1em;
            color: $color-text;
            text-transform: uppercase;

            span {
                display: block;
                font-size: 24px;
                color: $color-main;
                margin-top: -5px;
            }

            &--responsive {
                display: none;
            }
        }

        &-item {
            display: flex;
            column-gap: 30px;

            &__image {
                width: 200px;
                height: 273px;
                background-color: $color-bg--image;

                &--no-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-bg--image;
                
                    svg {
                      width: 40%;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__infos {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: -8px;
            }

            &__title {
                font-family: $font-family;
                font-size: $font-size--4;
                color: $color-text;
                margin-top: 11px;
            }

            &__text {
                color: $color-text;
                margin: 17px 0 15px;

                span {
                    text-transform: uppercase;
                }
            }

            &__buttons {
                display: flex;
                column-gap: 30px;
                margin: auto 0 0;
            }

            &__button {
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-white;
                box-shadow: $shadow;
                transition: $duration;

                svg {
                    width: 60px;
                    height: 60px;
                    fill: $color-main;
                    transition: $duration;
                }

                &--listen svg {
                    width: 23px;
                    height: 23px;
                }

                &:hover,
                &:focus {
                    background-color: $color-main;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }

        &__button {
            width: 270px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
            text-align: center;
            margin-top: 30px;
            border: 1px solid $color-white;
            transition: $duration;

            &:hover,
            &:focus {
                color: $color-main;
                background-color: $color-white;
            }
        }
    }



    //======================================================================================================
    // Newsletter
    //======================================================================================================
    .newsletter {
        width: 470px;
        margin-top: -8px;

        &__title {
            font-size: 44px;
            line-height: 1.1em;
            color: $color-text;
            text-transform: uppercase;

            span {
                display: block;
                font-size: 24px;
                color: $color-main;
                margin-top: -5px;
            }
        }

        &__text {
        font-size: $font-size--text-medium;
        line-height: 1.2em;
        font-weight: $font-weight-bold;
        color: $color-text; 
        margin-top: 21px;
        }

        &__button {
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
            background-color: $color-main;
            margin-top: 40px;
            transition: $duration;

            svg {
                fill: $color-second--dark;
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-text;
                background-color: $color-second--dark;

                svg {
                    fill: $color-main;
                }
            }
        }
    }



    //======================================================================================================
    // Plan
    //======================================================================================================
    .plan {
        margin-top: 120px;

        &__container {
            position: relative;
            display: flex;
            justify-content: flex-end;
        }

        &__image {
            width: 1070px;
            height: 380px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__content {
            position: absolute;
            left: 0;
            bottom: 34px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 29px;
        }

        &__title {
            font-size: 44px;
            line-height: 1.1em;
            color: $color-text;
            text-transform: uppercase;

            span {
                display: block;
                font-size: 24px;
                color: $color-fourth;
                margin-top: -5px;
            }
        }

        &__button {
            width: 370px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-transform: uppercase;
            text-align: center;
            background-color: $color-fourth;
            transition: $duration;

            svg {
                fill: $color-second--dark;
                transition: $duration;
            }

            &:hover,
            &:focus {
                background-color: $color-main;

                svg {
                    fill: $color-white;
                }
            }
        }
    }



    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .social-wall {
        padding-bottom: 95px;
        margin-top: 100px;

        .container {
            position: relative;

            &::after {
                content: '';
                width: 810px;
                height: 810px;
                position: absolute;
                top: 225px;
                left: 25%;
                background-color: $color-third;
                border-radius: $border-radius--round;
                z-index: -1;
            }
        }

        &__title {
            font-size: 44px;
            line-height: 1.1em;
            color: $color-text;
            text-transform: uppercase;

            span {
                display: block;
                font-size: 24px;
                color: $color-main;
                margin-top: -5px;
            }
        }

        &__items {
            margin-top: 20px;
        }
    
        #ff-stream-1 {
            min-height: auto !important;
            background-color: transparent !important;
            padding: 0 !important;
            margin: 0 -30px !important;
        
            .ff-stream-wrapper {
                height: 430px !important;
                display: flex !important;
                column-gap: 30px !important;
                margin: 0 !important;
            }
        
            .ff-item {
                height: 430px !important;

                .picture-item__inner {
                    height: 100% !important;
                    display: flex !important;
                    flex-direction: column !important;
                    box-shadow: $shadow !important;
                }

                .ff-item-cont {
                    height: auto !important;
                    max-height: calc(100% - 40.5px) !important;
                    flex: 1 !important;
                    display: flex !important;
                    flex-direction: column !important;
                }

                .ff-img-holder {
                    height: 220px !important;
        
                    img {
                        width: 100% !important;
                        height: 100% !important;
                        object-fit: cover !important;
                    }
                }

                .ff-content {
                    height: auto !important;
                    flex: 1 !important;
                    margin: 3.5% 7% !important;
                }

                .ff-item-meta {
                    margin: 3.5% 7% 7% !important;
                }

                .ff-item-bar {
                    height: 40.5px !important;
                }
            }
        
            .ff-loadmore-wrapper {
                display: none !important;
            }
        }

        &__button {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
        }

        &__more {
            margin: 0;
        }
    }

    //======================================================================================================
    // Responsive
    //======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .home-slideshow {
            .identity {
                top: 70px;

                &__title {
                    font-size: 65px;
                }
            }

            &__effect {
                svg {
                    width: 2270px;
                    height: 80px;
                }
            }

            .slideshow {
                height: 465px;

                &__container {
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 2.66%, rgba(0, 0, 0, 0) 56.73%);
                }

                &__content {
                    left: 12px;
                    bottom: 168px;
                }

                &__pagination {
                    left: 12px;
                    bottom: 123px;
                }
            }
        }



        .ac-qa {
            margin-top: -5px;

            &__container {
                &::before {
                    width: 600px;
                    height: 600px;
                    top: 37px;
                    right: 87%;
                }

                &::after {
                    left: 89.5%;
                }
            }
        }



        .ac {
            width: 620px;

            &__items {
                padding-bottom: 27px;
                margin-top: 19px;
            }

            &-item {
                width: 300px;

                &__img {
                    width: 100%;
                    height: 280px;
                }

                &__content {
                    width: 270px;
                    height: 200px;
                    row-gap: 8px;
                    padding: 10px 20px;
                    margin-top: -40px;
                }
            }
        }



        .qa {
            &__items {
                row-gap: 21px;
            }

            &-item {
                width: 270px;
                height: 94px;
                column-gap: 7px;
                padding: 5px 10px;

                svg {
                    width: 80px;
                    height: 80px;
                }

                &__title {
                    span {
                        font-size: $font-size--text;
                    }
                }
            }
        }



        .ag {
            margin-top: 99px;

            .container {
                &::after {
                    width: 600px;
                    height: 600px;
                    top: 2px;
                    left: 89.5%;
                }
            }

            &__smallitems {
                row-gap: 20px;
            }

            &-bigitem {
                width: 455px;

                &__img {
                    width: 280px;
                    height: 400px;       
                }

                &__infos {
                    top: -33px;
                }

                &__dates {
                    width: 240px;
                    height: 240px;
                    row-gap: 2px;
                    padding: 0 0 35px;

                    svg {
                        width: 19px;
                        height: 12px;
                    }
                }

                &-date {            
                    &__day {
                        font-size: 40px;
                    }
            
                    &__month {
                        font-size: 25px;
                        margin-top: -7px;
                    }
                }

                &__content {
                    width: 220px;
                    max-height: 235px;
                    row-gap: 8px;
                    padding: 22px 15px;
                    margin-top: -50px;
                    margin-right: 5px;
                }

                &__title {
                    line-height: 1.1em;
                }

                &__text {
                    line-height: 1.25em;
                }
            }

            &-smallitem {
                width: 460px;

                &__img {
                    width: 140px;
                    height: 190px;  
                }

                &__dates {
                    width: 100px;

                    &::after {
                        width: 180px;
                        height: 180px;
                    }
                }

                &-date {            
                    &__day {
                        font-size: 30px;
                    }
            
                    &__month {
                        font-size: $font-size--text-medium;
                    }
                }

                &__content {
                    width: 220px;
                    padding: 10px 15px;
                }
            }
        }



        .kio-news {
            margin-top: 87px;

            &__effect {
                bottom: 114px;

                svg {
                    width: 2270px;
                    height: 430px;          
                }
            }
        }



        .kiosque {
            width: 480px;

            &-item {
                column-gap: 20px;

                &__image {
                    width: 170px;
                    height: 230px;
                }

                &__title {
                    margin-top: 2px;
                }

                &__text {
                    margin: 6px 0 10px;
                }

                &__button {
                    width: 50px;
                    height: 50px;

                    svg {
                        width: 50px;
                        height: 50px;
                    }

                    &--listen svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            &__button {
                margin-top: 30px;
            }
        }



        .newsletter {
            width: 380px;
            margin-top: -8px;

            &__text {
                margin-top: 11px;
            }

            &__button {
                font-size: $font-size--text-small;
                margin-top: 30px;
            }
        }



        .plan {
            margin-top: 80px;

            &__image {
                width: 860px;
                height: 305px;
            }

            &__content {
                bottom: 30px;
                row-gap: 9px;
            }
        }



        .social-wall {
            padding-bottom: 77px;
            margin-top: 81px;

            .container {
                &::after {
                    width: 600px;
                    height: 600px;
                    top: 211px;
                    left: 30%;
                }
            }
            
            #ff-stream-1 {
                margin: 0 -20px !important;

                .ff-stream-wrapper {
                    height: 390px !important;
                    column-gap: 20px !important;
                }
            
                .ff-item {
                    height: 390px !important;
    
                    .ff-img-holder {
                        height: 165px !important;
                    }
                }
            }
        }
    }


    // 960
    @media screen and (max-width: $medium) {
        .home-slideshow {
            .identity {
                top: 56px;

                &__title {
                    font-size: 55px;
                }
            }

            &__effect {
                svg {
                    width: 1812px;
                    height: 64px;
                }
            }

            .slideshow {
                height: 370px;

                &__container {
                    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 3.94%, rgba(0, 0, 0, 0) 84.06%);
                }

                &__content {
                    bottom: 136px;
                }

                &__title {
                    font-size: $font-size--4;
                }

                &__pagination {
                    left: 10px;
                    bottom: 100px;
                }

                &--video {
                    video {
                        display: none;
                    }

                    img {
                        display: block;
                    }
                }
            }
        }



        .ac-qa {
            margin-top: 11px;

            &__container {
                flex-direction: column;
            
                &::before {
                    top: 39px;
                    right: 80.5%;
                }

                &::after {
                    top: unset;
                    left: 65%;
                    bottom: -61px;
                }
            }
        }

        .ac {
            width: 100%;
        }

        .qa {
            width: 100%;
            margin-top: 59px;

            &__items {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                row-gap: 18px;
                column-gap: 18px;
            }

            &-item {
                width: 300px;
                height: 100px;

                &:last-child {
                    margin: 0 auto;
                }
            }
        }

        .ag {
            .container {
                &::after {
                    width: 0;
                    height: 0%;
                }
            }

            &__items {
                flex-direction: column;
                row-gap: 30px;
            }

            &__smallitems {
                flex-direction: row;
                column-gap: 20px;
            }

            &-bigitem {
                width: 100%;

                &__img {
                    width: 300px;
                    height: 430px;       
                }

                &__infos {
                    width: 360px;
                    align-items: flex-start;
                }

                &__dates {
                    padding: 0;

                    &::after {
                        top: 60%;
                    }
                }

                &__content {
                    width: 100%;
                    height: 220px;
                    row-gap: 8px;
                    padding: 34px 26px;
                    margin-top: -29px;
                    margin-right: 0;
                }
            }

            &-smallitem {
                width: 300px;
                flex-wrap: wrap;

                &__dates {
                    width: 160px;
                    height: 180px;
                    flex-direction: row;
                    justify-content: center;
                    column-gap: 10px;
                    padding-right: 12px;

                    &::after {
                        left: 44%;
                    }

                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &-date {
                    &__month {
                        margin-top: 0;
                    }            
                }

                &__content {
                    width: 250px;
                    padding: 10px 25px;
                    margin: -18px auto 0;
                }
            }
        }



        .kio-news {
            margin-top: 80px;

            &__container {
                flex-direction: column-reverse;
            }

            &__effect {
                bottom: 142px;

                svg {
                    width: 1820px;
                    height: 345px;          
                }
            }
        }

        .newsletter {
            width: 100%;
            margin-top: 0;
        }


        .kiosque {
            width: 100%;
            margin-top: 69px;

            &-item {
                column-gap: 31px;

                &__image {
                    width: 225px;
                    height: 300px;
                }

                &__infos {
                    margin-top: 5px;
                }

                &__title {
                    margin-top: 28px;
                }

                &__text {
                    margin: 2px 0 20px;
                }
            }

            &__button {
                margin-top: 30px;
            }
        }



        .plan {
            margin-top: 50px;

            &__image {
                width: 540px;
                height: 200px;
            }

            &__content {
                bottom: -62px;
            }

            &__button {
                width: 300px;
            }
        }



        .social-wall {
            padding-bottom: 77px;
            margin-top: 142px;

            .container {
                &::after {
                    top: 250px;
                    left: 39%;
                }
            }
            
            #ff-stream-1 {            
                .ff-item:nth-child(3) {
                    display: none !important;
                }
            }

            &__button {
                justify-content: center;
            }
        }
    }


    // 640
    @media screen and (max-width: $small) {
        .home-slideshow {
            .identity {
                top: 10px;

                &__title {
                    font-size: 40px;
                }
            }

            &__effect {
                svg {
                    width: 1210px;
                    height: 46px;
                    bottom: -2px;
                }
            }

            .slideshow {
                height: 245px;

                &__container {
                    &::after {
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 3.77%, rgba(0, 0, 0, 0) 80.47%);
                    }
                }

                &__content {
                    bottom: 81px;
                }

                &__title {
                    font-size: $font-size--text-large;
                }

                &__pagination {
                    bottom: 52px;
                }
            }
        }


        .ac-qa {
            margin-top: 60px;

            &__container {            
                &::before {
                    right: 60.5%;
                }

                &::after {
                    left: 78%;
                    bottom: 82px;
                }
            }
        }

        .ac {
            width: 100%;

            &__pagination {
                display: none;

                &--responsive {
                    display: flex;
                    justify-content: center;
                }
            }

            &__button {
                margin-top: 53px;
            }
        }



        .qa {
            margin-top: 80px;

            &__items {
                row-gap: 20px;
            }
        }



        .ag {
            margin-top: 80px;

            &__items {
                row-gap: 61px;
            }

            &__smallitems {
                flex-direction: column;
            }

            &-bigitem {
                flex-direction: column;

                &__infos {
                    width: 100%;
                    position: relative;
                    top: unset;
                    right: unset;
                    align-items: center;
                }

                &__dates {
                    height: 140px;
                    flex-direction: row;
                    column-gap: 2px;

                    &::after {
                        height: 240px;
                        top: 52%;
                    }

                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &__content {
                    height: 222px;
                    padding: 35px 16px;
                    margin-top: 0;
                }
            }

            &__buttons {
                flex-direction: column-reverse;
                align-items: center;
                row-gap: 30px;
            }
        }



        .kio-news {
            &__effect {
                bottom: 138px;

                svg {
                    width: 1210px;
                    height: 230px;          
                }
            }
        }

        .newsletter {
            &__title {
                span {
                    margin-top: 3px;
                    line-height: 1.25em;
                }
            }

            &__text {
                margin-top: 30px;
            }

            &__button {
                padding: 0 45px;
                margin-top: 40px;

                svg {
                    flex-shrink: 0;
                }
            }
        }


        .kiosque {
            margin-top: 50px;

            &__title {
                display: none;

                &--responsive {
                    display: block;
                }
            }

            &-item {
                width: 225px;
                flex-direction: column;
                row-gap: 12px;
                margin-top: 9px;

                &__infos {
                    margin-top: 0;
                }

                &__title {
                    margin-top: 0;
                }

                &__text {
                    margin: 6px 0 0;
                }

                &__buttons {
                    margin-top: 18px;
                }
            }

            &__button {
                margin-top: 30px;
            }
        }



        .plan {
            &__container {
                flex-direction: column;
            }

            &__image {
                width: 300px;
                height: 120px;
            }

            &__content {
                position: relative;
                left: unset;
                bottom: unset;
                margin-top: 15px;
            }
        }



        .social-wall {
            padding-bottom: 28px;
            margin-top: 80px;

            .container {
                &::after {
                    top: 89%;
                    left: 12%;
                }
            }

            &__items {
                margin-top: 9px;
            }

            #ff-stream-1 {
                margin: 0 !important;
                .ff-stream-wrapper {
                    height: 800px !important;
                    flex-direction: column !important;
                    row-gap: 20px !important;
                }

                .ff-item:nth-child(1) {
                    margin-bottom: 20px !important;
                }
            }
        }
    }
}