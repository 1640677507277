.cover {
    position: relative;
    overflow: hidden;

    &__content {
        display: flex;
        column-gap: 30px;
    }

    &__image {
        width: 670px;
        height: auto;
        min-height: 466px;
        position: relative;
        display: flex;
        align-items: center;
        background: $color-bg--image;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.52) 3.02%, rgba(0, 0, 0, 0) 64.48%);
            z-index: 1;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: top;
        }
    }

    &__bigtitle {
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        font-family: $font-family;
        font-size: 70px;
        line-height: 70px;
        font-weight: $font-weight-black;
        color: $color-white;
        text-transform: uppercase;
        text-align: center;
        text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
        margin: 0;
        z-index: 2;

        &:hover,
        &:focus {
            color: $color-white;
        }
    }

    &__informations {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 60px 0;

        &::after {
            content: '';
            width: 200px;
            height: 200px;
            position: absolute;
            top: 50px;
            left: -115px;
            background-color: $color-third;
            border-radius: $border-radius--round;
            z-index: -1;
        }
    }

    &__title {
        line-height: 1.1em;
        color: $color-text;
        margin: 0;
    }

    &__intro {
        font-size: $font-size--5;
        color: $color-text;
        margin-top: 20px;
    }

    &__effect {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;

        svg {
            width: 2840px;
            height: 100px;
            position: absolute;
            left: 50%;
            bottom: -17px;
            transform: translateX(-50%);
            fill: $color-white;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover {                
            &__image {
                width: 470px;
                min-height: 400px;
            }

            &__bigtitle {
                font-size: 55px;
            }
        
            &__effect {        
                svg {
                    width: 2270px;
                    height: 80px;
                    bottom: -14px;
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {  
            &__content {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 30px;
            }       

            &__image {
                width: 100%;
                min-height: 370px;
            }

            &__informations {
                padding: 0 0 75px;

                &::after {
                    top: -85px;
                    left: -85px;
                }
            }

            &__effect {        
                svg {
                    width: 1810px;
                    height: 64px;
                    bottom: -11px;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .cover {  
            &__content {
                row-gap: 20px;
            }       

            &__image {
                min-height: 250px;
            }

            &__bigtitle {
                top: 25px;
                font-size: 35px;
            }

            &__informations {
                padding: 0 0 60px;

                &::after {
                    width: 160px;
                    height: 160px;
                    top: -60px;
                    left: -60px;
                }
            }

            &__effect {        
                svg {
                    width: 1210px;
                    height: 42px;
                    bottom: -7px;
                }
            }
        }
    }