.breadcrumb {
    margin-top: 30px;

    ul  {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 8px;
        row-gap: 10px;
    }

    li {
        font-size: $font-size--text-small;
        color: $color-text;

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        a:hover,
        a:focus {
            color: $color-main;
        }
    }

    svg {
        fill: $color-main;
    }
}