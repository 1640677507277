.card__see-more,
.card__see-less {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 26px;
    height: 26px;
    background: $color-main;
    cursor: pointer;
    border-radius: $border-radius--round;
    transition: all $duration;

    &:hover {
        background: $color-second--dark;

        &::before {
            color: $color-text;
        }
    }

    &::before {
        color: $color-white;
        font: normal 16px/1 'dashicons';
        left: 0.5px;
        position: relative;
  }
}

.card__see-more {
    &::before {
        content: "\f543";
    }
}

.card__see-less {
    &::before {
        content: "\f460";
    }
}

.card__more {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 20px;
    background: $color-white;
    opacity: 0;
    pointer-events: none;
    transition: all .45s;

    &.see-more {
        opacity: 1;
        pointer-events: auto;
    }
}