.footer {
    position: relative;
    z-index: 2;

    &::after {
        content: '';
        width: 100%;
        height: 140px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $color-second;
        z-index: -1;
    }

    &__effect {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;

        svg {
            width: 2890px;
            height: 690px;
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);            
        }

        &--960,
        &--640 {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 60px;
        padding-bottom: 25px;
    }

    &__logo {
        width: 330px;
        height: 133px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        column-gap: 110px;
        padding-left: 20px;
        margin-top: 54px;
    }

    &__mairie {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        font-family: $font-family;
        font-size: $font-size--4;
        color: $color-text;
        margin: 0;
    }

    &__text {
        line-height: 1.3em;
        color: $color-text;
        margin-top: 5px;
    }

    &__tel {
        width: 300px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        color: $color-white;
        text-transform: uppercase;
        background-color: $color-main;
        margin-top: 34px;
        transition: $duration;

        svg {
            fill: $color-second--dark;
            transition: $duration;
        }

        &:hover,
        &:focus {
            color: $color-text;
            background-color: $color-second--dark;

            svg {
                fill: $color-main;
            }
        }
    }

    &__horaires {
        width: 470px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__contact {
        width: 470px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        color: $color-text;
        text-transform: uppercase;
        background-color: $color-second--dark;
        margin-top: 34px;
        transition: $duration;

        svg {
            fill: $color-main;
            transition: $duration;
        }

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &__ville {
        width: 100px;
        height: 94px;
        margin: 0 0 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__menu {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 80px;
        margin-top: 62px;
        z-index: 2;
    }

    &__link a {
        position: relative;
        color: $color-text;
        transition: $duration;

        &::after {
            content: '';
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $color-second--dark;
            border-radius: $border-radius--round;
            opacity: 0;
            transition: $duration;
            z-index: -1;
        }

        &:hover,
        &:focus {
            color: $color-main;

            &::after {
                opacity: 1;
            }
        }
    }
}

body:not(.home) .footer {
    &::before {
        content: '';
        width: 810px;
        height: 810px;
        position: absolute;
        top: 15px;
        left: calc(100% - ((100% - 1200px) / 2) - 742px) !important;
        background-color: $color-third;
        border-radius: $border-radius--round;
        z-index: -2;
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {    
        &__effect {    
            svg {
                width: 2270px;
                height: 550px;        
            }
        }
    
        &__container {
            padding-top: 57px;
            padding-bottom: 25px;
        }
    
        &__logo {
            width: 300px;
            height: 121px;
        }
    
        &__content {
            column-gap: 93px;
            margin-top: 46px;
        }
    
        &__mairie {
            width: 240px;
        }

        &__text {
            font-size: $font-size--text-small;
        }
    
        &__tel {
            width: 240px;
            font-size: $font-size--text;
            margin-top: 30px;
        }
    
        &__horaires {
            width: 380px;
        }
    
        &__contact {
            width: 380px;
            margin-top: 30px;
        }

        &__ville {
            width: 90px;
            height: 85px;
            margin-top: 22px;
        }
    
        &__menu {
            column-gap: 40px;
            margin-top: 57px;
        }
    }

    body:not(.home) .footer {
        &::before {
            top: 10px;
            left: calc(100% - ((100% - 960px) / 2) - 700px) !important;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer { 
        &::after {
            height: 170px;
        }  

        &__effect {    
            svg {
                width: 1820px;
                height: 861px;  
                top: 0;      
            }

            &--desktop {
                display: none;
            }

            &--960 {
                display: block;
            }
        }
    
        &__container {
            padding-top: 90px;
            padding-bottom: 33px;
        }
    
        &__content {
            flex-wrap: wrap;
            row-gap: 45px;
            column-gap: 0;
            padding-left: 0;
        }
    
        &__mairie {
            width: 100%;
        }

        &__text {
            margin-top: 7px;
        }
    
        &__tel,
        &__contact {
            margin-top: 28px;
        }

        &__ville {
            width: 90px;
            height: 85px;
            margin: auto 9px 0 auto;
        }
    
        &__menu {
            width: 450px;
            flex-wrap: wrap;
            column-gap: 26px;
            row-gap: 54px;
            margin: 84px auto 0;
        }
    }

    body:not(.home) .footer {
        &::before {
            top: 5px;
            left: calc(100% - ((100% - 640px) / 2) - 650px) !important;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer { 
        &::after {
            height: 330px;
        }  

        &__effect {    
            svg {
                width: 1210px;
                height: 989px;  
                top: -23px;      
            }

            &--960 {
                display: none;
            }

            &--640 {
                display: block;
            }
        }
    
        &__container {
            padding-top: 74px;
            padding-bottom: 42px;
        }
    
        &__content {
            row-gap: 40px;
        }
    
        &__tel,
        &__contact {
            margin-top: 30px;
        }

        &__contact {
            width: 100%;
        }

        &__horaires {
            width: 100%;
        }

        &__ville {
            margin: 0 auto 0 0;
        }
    
        &__menu {
            width: 100%;
            column-gap: 60px;
            margin: 75px auto 0;
        }
    }

    body:not(.home) .footer {
        &::before {
            top: 0;
            left: calc(100% - ((100% - 320px) / 2) - 550px) !important;
        }
    }
}