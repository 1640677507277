header {
    width: 100%;
    position: relative;
    background-color: $color-white;
    z-index: 999;
}

//======================================================================================================
// Fil d'infos
//======================================================================================================
.fil-infos {
    position: relative;
    margin-top: 25px;
    margin-bottom: 10px;
    z-index: 3;

    &__container {
        height: 65px;
        display: flex;
        align-items: center;
        column-gap: 25px;
        background-color: $color-white;
        padding: 0 30px 0 20px;
        box-shadow: $shadow;
    }

    &__left {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

    &__title {
        font-size: $font-size--4;
        line-height: 1.1em;
        font-weight: $font-weight-bold;
        color: $color-main;
    }

    &__text {
        color: $color-text;
        transition: $duration;

        &:hover,
        &:focus {
            color: $color-main;
        }
    }

    &__right {
        margin: 0 0 0 auto;
    }

    .swiper-pagination {
        position: relative;
        display: flex;
        column-gap: 15px;

        &-bullet {
            width: 20px;
            height: 20px;
            background-color: $color-white;
            border: 3px solid $color-main;
            border-radius: $border-radius--round;
            opacity: 1;

            &-active {
                background: rgba(0, 119, 114, .5);
            }
        }
    }
}

.header {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &-nav {
        width: 100%;
        position: relative;
        background-color: $color-white;

        &__container {
            height: 100px;
            display: flex;
            justify-content: space-between;
        }

        &__effect {
            width: 100%;
            height: 200px;
            position: absolute;
            bottom: 0;
            right: 0;
            overflow: hidden;

            &::before {
                content: "";
                width: 625px;
                height: 625px;
                position: absolute;
                left: calc(100% - ((100% - 1200px) / 2) - 375px) !important;
                bottom: -120px;
                background-color: $color-second;
                border-radius: $border-radius--round;
            }
        }

        &--fixed {
            position: fixed;
            top: 0;
            left: 0;
            box-shadow: $shadow;
            z-index: 999;
        }
    }
}

body {
    &:not(.home) {
        .fil-infos {
            display: none;
        }
    }

    &.admin-bar {
        .header-nav--fixed {
            top: 32px;
        }
    }

    &.fixed {
        .front-page,
        .cover {
            margin-top: 100px;
        }
    }

    &.accessibility {
        .header-nav--fixed {
            top: 62px;
        }

        &.admin-bar {
            .header-nav--fixed {
                top: 94px;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}


.tools {
    display: flex;
    align-items: center;
    column-gap: 33px;
    z-index: 1;

    &__search {
        display: flex;
        align-items: center;
        column-gap: 5px;

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        svg {
            width: 40px;
            height: 40px;
            fill: $color-text;
            transition: $duration;
        }

        span {
            font-family: $font-family--heading;
            font-size: $font-size--text-large;
            color: $color-text;
            text-transform: uppercase;
            transition: $duration;
        }

        &:hover,
        &:focus {
            cursor: pointer;

            svg {
                fill: $color-main;
            }

            span {
                color: $color-main;
            }
        }
    }

    &__accessibility {
        display: flex;
        align-items: center;
        background-color: $color-bg--transparent;
        margin: 0;
        padding: 0;
        border: 0;

        svg {
            fill: $color-text;
            transition: $duration;
        }

        &:hover,
        &:focus {
            cursor: pointer;
            background-color: $color-bg--transparent;
            
            svg {
                fill: $color-main;
            }
        }
    }
}



//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .fil-infos {        
            &__container {
                padding: 0 25px 0 20px;
            }
        }
        
        .header {        
            &-nav {                
                &__effect {        
                    &::before {
                        left: calc(100% - ((100% - 960px) / 2) - 375px) !important;
                    }
                }

            }
        }
                
        .tools {
            column-gap: 25px;
        
            &__search {        
                span {
                    font-size: $font-size--text-medium;
                }
            }
        }
    }


    // 960
    @media screen and (max-width: $medium) {
        .fil-infos {        
            &__container {
                height: 144px;
                flex-direction: column;
                justify-content: space-between;
                padding: 12px 20px 20px;
            }
        
            &__left {
                column-gap: 10px;
            }

            &__middle {
                width: 100%;
            }
        
            &__right {
                margin: 0;
            }

            &__text {
                text-align: center;
            }
        }
        
        .header {   
            box-shadow: $shadow;     
            &-nav {  
                &__effect {
                    height: 280px;
        
                    &::before {
                        left: calc(100% - ((100% - 640px) / 2) - 275px) !important;
                        bottom: -40px;
                    }
                }
            }
        }
        
        
        .tools {
            column-gap: 20px;
        
            &__search {      
                span {
                    display: none;
                }
            }
        }

        .privacy {
            &__container {
                flex-wrap: wrap;
            }

            &__button {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }


    // 640
    @media screen and (max-width: $small) {
        .fil-infos {
            margin-bottom: 10px;
        
            &__container {
                height: 169px;
                padding: 12px 25px 25px;
            }
        }
        
        .header {        
            &-nav {  
                &__container {
                    height: 80px;
                }

                &__effect {
                    height: 285px;
        
                    &::before {
                        left: calc(100% - ((100% - 320px) / 2) - 163px) !important;
                        bottom: -135px;
                    }
                }
            }
        }
    }